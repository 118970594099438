import { createAsyncThunk } from '@reduxjs/toolkit';
import askItApi, { askItBetaApi } from "../../../services/Customer/askItApi";
import { RootState } from "../../index";
import { ASK_AI_BETA_TAG } from "../../../services/Customer/addonsApi";

export const fetchAskAISessions = createAsyncThunk(
  'user/fetchAskAISessions',
  async (_, thunkAPI) => {
    const {
      user: {
        addonTags,
      },
    } = thunkAPI.getState() as RootState;

    const askItApiService = addonTags !== null && addonTags.includes(ASK_AI_BETA_TAG) ? askItBetaApi : askItApi;

    return await askItApiService.getChatSessions();
  },
);

export default {
  fetchAskAISessions,
};
