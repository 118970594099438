import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js/auto'; // Automatically registers all components
import moment from "moment/moment";
import styles from "./HHSReport.module.less";

export type HistoryChartData = {
  name: string,
  params: {
    max_x: string,
    max_y: number,
    min_x: string,
    min_y: number,
    x: string[],
    y: number[],
  },
  type: string,
};

const HabitScore = (props: {
  dataHistoryChart: HistoryChartData,
}) => {
  const { dataHistoryChart } = props;

  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        if (chartInstance.current) {
          chartInstance.current.destroy(); // Destroy previous instance
        }

        const formattedLabels = dataHistoryChart.params.x.map((dateStr) => {
          if (typeof dateStr !== 'string') {
            return ''; // Fallback to an empty string
          }
          return moment(dateStr).format('MMM/DD').split('/');
        });

        chartInstance.current = new Chart(ctx, {
          type: 'line', // Type of chart
          data: {
            labels: formattedLabels, // Labels for the x-axis
            datasets: [
              {
                label: 'Habit Score',
                data: dataHistoryChart.params.y, // Values for the y-axis
                borderColor: '#005550', // Line color
                backgroundColor: '#005550', // Background color for points
                fill: false, // Disable area fill
                pointStyle: false,
                pointBackgroundColor: '#005550', // Color of data points
                borderWidth: 1,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false, // Hide the legend
              },
              tooltip: {
                enabled: false,
              },
            },
            interaction: {
              mode: 'index',
              intersect: false,
            },
            scales: {
              x: {
                type: 'category', // Use category scale
                ticks: {
                  autoSkip: false, // Automatically skip labels if they overlap
                  maxRotation: 0, // Disable label rotation
                  color: '#005550', // Set x-axis label color
                  font: {
                    size: 12, // Font size
                  },
                },
                grid: {
                  lineWidth: 1, // Grid line width
                  color: '#232D37', // Set grid line color
                  display: false, // Hide vertical grid lines
                },
                border: {
                  display: true, // Show the left border line
                  color: '#232D37', // Dark color for the left border line
                  width: 1, // Width of the border line
                },
                offset: true, // Add padding to the left and right sides of the x-axis
              },
              y: {
                min: dataHistoryChart.params.min_y, // Minimum value for y-axis
                max: dataHistoryChart.params.max_y, // Maximum value for y-axis
                ticks: {
                  autoSkip: false,
                  stepSize: 20, // Step size for ticks
                  callback: (value) => `${value}`, // Format tick labels
                  color: '#005550', // Set y-axis label color
                  font: {
                    size: 12, // Font size
                  },
                  padding: 10,
                  mirror: false, // Prevent ticks from appearing on both sides
                  display: true, // Show y-axis ticks
                },
                grid: {
                  color: '#C3C8CD', // Set grid line color
                  lineWidth: 1, // Grid line width
                  drawTicks: false, // Remove tick marks
                  display: true, // Show horizontal grid lines
                },
                border: {
                  display: true, // Show the left border line
                  color: '#232D37', // Dark color for the left border line
                  width: 1, // Width of the border line
                },
              },
            },
            layout: {
              padding: {
                left: 0,
                right: 10,
                top: 10,
                bottom: 0,
              },
            },
          },
        });
      }
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy(); // Clean up on unmount
      }
    };
  }, [dataHistoryChart]);

  return (
    <div className={`${styles.habitScore} ${styles.box} ${styles.boxGray}`}>
      <p className={styles.boxTitle}>Habit Score History</p>
      <div className={styles.habitScoreImage}>
        <canvas ref={chartRef}/>
      </div>
    </div>
  );
};

export default HabitScore;
