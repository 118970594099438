import React from 'react';
import styles from "./HHSReport.module.less";

export type GaugeChartData = {
  name: string,
  params: { max_value: number, min_value: number, value: number },
  type: string,
};

const GaugeChart = (props: {
  data: GaugeChartData,
  text: string,
}) => {
  const { data, text } = props;

  const indicatorRotationByValue = ((data.params.value - 50) / 50) * 90;

  const topCoefficient = (Math.abs(50 - data.params.value) / 50) * -5;

  return (
    <div className={`${styles.box} ${styles.boxEffort}`} id="scoreGraphBox">
      <p className={`${styles.boxTitle} ${styles.boxEffortTitle}`}>Healthspan Habit Score</p>
      <div className={styles.imageContainer}>
        <svg
          className={styles.mainIcon}
          xmlns="http://www.w3.org/2000/svg"
          width="143"
          height="69"
          viewBox="0 0 143 69"
          fill="none"
        >
          <path
            d="M16.8749 76.5085C17.5053 76.5021 18.1097 76.2569 18.5664 75.8224C19.0232 75.3879 19.2981 74.7964 19.3359 74.1671L19.4804 71.5467C21.1294 42.3833 44.0306 19.5368 71.6098 19.5368C99.189 19.5368 122.085 42.3833 123.734 71.5267L123.884 74.1472C123.922 74.7756 124.196 75.3664 124.651 75.8008C125.107 76.2352 125.71 76.4809 126.34 76.4886H139.96C140.296 76.4844 140.627 76.4123 140.935 76.2767C141.242 76.1411 141.518 75.9448 141.748 75.6996C141.977 75.4543 142.155 75.1653 142.27 74.8497C142.384 74.5341 142.434 74.1985 142.416 73.8632L142.227 70.4656C140.02 31.4233 108.998 0.840088 71.6098 0.840088C34.2215 0.840088 3.19988 31.4233 0.992947 70.4656L0.948112 71.2228C0.909635 71.8964 1.00836 72.5708 1.2383 73.205C1.46824 73.8392 1.8246 74.4201 2.28578 74.9125C2.74696 75.4049 3.30335 75.7985 3.92119 76.0694C4.53904 76.3403 5.20547 76.4829 5.88008 76.4886L16.8749 76.5085Z"
            fill="url(#paint0_linear_583_179)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_583_179"
              x1="142.421"
              y1="38.6868"
              x2="0.938147"
              y2="38.6868"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00C688"/>
              <stop offset="0.31" stopColor="#02C687"/>
              <stop offset="0.42" stopColor="#09C584"/>
              <stop offset="0.5" stopColor="#14C47E"/>
              <stop offset="0.57" stopColor="#25C376"/>
              <stop offset="0.62" stopColor="#3BC16C"/>
              <stop offset="0.67" stopColor="#57BF5F"/>
              <stop offset="0.71" stopColor="#77BC50"/>
              <stop offset="0.75" stopColor="#9DB93E"/>
              <stop offset="0.79" stopColor="#C8B62A"/>
              <stop offset="0.83" stopColor="#FFB110"/>
              <stop offset="0.86" stopColor="#FEA712"/>
              <stop offset="0.93" stopColor="#F75D1E"/>
              <stop offset="1" stopColor="#994154"/>
            </linearGradient>
          </defs>
        </svg>
        <div
          style={{
            rotate: `${indicatorRotationByValue}deg`,
            top: `${topCoefficient}px`,
          }}
          className={styles.indicator}
        >
          <svg
            className={styles.cursorIcon}
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="23"
            viewBox="0 0 25 21"
            fill="none"
          >
            <path
              d="M17.5731 14.4449L6.54618 7.99603L0.949687 9.1724C0.855853 9.1909 0.758715 9.18198 0.669804 9.14675C0.580893 9.11151 0.503976 9.05142 0.448294 8.97366C0.392611 8.8959 0.3605 8.80376 0.355774 8.70824C0.351048 8.61272 0.373906 8.51785 0.42164 8.43497L4.75099 1.07118C4.80078 0.989697 4.87298 0.924245 4.95891 0.882611C5.04484 0.840978 5.14092 0.824921 5.23573 0.836348C5.33053 0.847775 5.42001 0.886207 5.49359 0.947067C5.56717 1.00793 5.62173 1.08864 5.65073 1.17962L7.35189 6.67931L18.3795 13.1331L23.9753 11.9518C24.0691 11.9333 24.1663 11.9422 24.2552 11.9774C24.3441 12.0127 24.421 12.0727 24.4767 12.1505C24.5324 12.2283 24.5645 12.3204 24.5692 12.4159C24.5739 12.5114 24.5511 12.6063 24.5033 12.6892L20.174 20.053C20.1245 20.1344 20.0527 20.1999 19.9672 20.2417C19.8817 20.2836 19.7859 20.3 19.6913 20.2891C19.5967 20.2782 19.5072 20.2404 19.4334 20.1802C19.3596 20.12 19.3046 20.04 19.2749 19.9495L17.5731 14.4449Z"
              fill="white"
            />
          </svg>
        </div>

        <div className={styles.overlayTextMin}>
          {data.params.min_value}
        </div>
        <div className={styles.overlayTextMax}>
          {data.params.max_value}
        </div>

        <div className={styles.overlayText}>
          {data.params.value}
        </div>
      </div>
      <p className={styles.boxEffortText} id="scoreGraphText">
        {text}
      </p>
    </div>
  );
};

export default GaugeChart;
