/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit';
import { BruState } from './types';
import { parseFiles } from './actions';

export const bruState: BruState = {
  parsing: false,
  parsedResults: null,
  groupId: null,
  selectedCountryAbbr: 'US',
};

const bruSlice = createSlice({
  name: 'bru',
  initialState: bruState,
  reducers: {
    resetParsedResults: (state) => {
      console.log('Parsed files reset');
      state.parsedResults = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(parseFiles.pending, ((state) => {
      state.parsing = true;
    }));

    builder.addCase(parseFiles.fulfilled, ((state, action) => {
      const {
        results,
        groupId,
      } = action.payload.parsedFiles;
      state.parsing = false;
      state.parsedResults = results;
      state.groupId = groupId;
      state.selectedCountryAbbr = action.payload.selectedCountryAbbr;
    }));
  },
});

export const {
  resetParsedResults,
} = bruSlice.actions;

export default bruSlice.reducer;
