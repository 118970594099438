import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js/auto'; // Automatically registers all components
import annotationPlugin from 'chartjs-plugin-annotation';
import moment from "moment/moment";
import styles from "./HHSReport.module.less";

Chart.register(annotationPlugin);

export type BedTimeHistoryChartData = {
  name: string,
  params: {
    max_x: string,
    max_y: number,
    min_x: string,
    min_y: number,
    x: string[],
    y: number[],
    zones: [{ range: number[], weight: number, color: string }],
    y_label: string,
  },
  type: string,
};
const hex2rgba = (hex: any, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x: any) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};
const BedTimeHistoryChart = (props: {
  bedTimeHistoryChartData: BedTimeHistoryChartData
}) => {
  const { bedTimeHistoryChartData } = props;

  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        if (chartInstance.current) {
          chartInstance.current.destroy(); // Destroy previous instance
        }

        const formattedLabels = bedTimeHistoryChartData.params.x.map((dateStr) => {
          if (typeof dateStr !== 'string') {
            return ''; // Fallback to an empty string
          }
          return moment(dateStr).format('MMM/DD').split('/');
        });

        chartInstance.current = new Chart(ctx, {
          type: 'line', // Type of chart
          data: {
            labels: formattedLabels, // Labels for the x-axis
            datasets: [
              {
                label: bedTimeHistoryChartData.params.y_label,
                data: bedTimeHistoryChartData.params.y, // Values for the y-axis
                borderColor: '#005550', // Line color
                backgroundColor: '#005550', // Background color for points
                fill: false, // Disable area fill
                pointStyle: 'circle',
                pointRadius: 5,
                pointBackgroundColor: '#005550', // Color of data points
                borderWidth: 1,
                borderDash: [4, 4], // Dashed line style
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false, // Hide the legend
              },
              tooltip: {
                enabled: false,
              },
              filler: {
                propagate: true,
              },
              annotation: {

                annotations: [
                  ...bedTimeHistoryChartData.params.zones
                    .filter((zone) => zone.color.length > 0)
                    .map((zone): any => ({
                      type: 'box',
                      xMin: -1,
                      xMax: 8,
                      yMin: zone.range[0],
                      yMax: zone.range[1],
                      backgroundColor: hex2rgba(zone.color, 0.2),
                      drawTime: 'beforeDatasetsDraw',
                      borderWidth: 0,
                    })),
                ],
              },
            },
            interaction: {
              mode: 'index',
              intersect: false,
            },
            scales: {
              x: {
                type: 'category', // Use category scale
                ticks: {
                  autoSkip: false, // Automatically skip labels if they overlap
                  maxRotation: 0, // Disable label rotation
                  color: '#005550', // Set x-axis label color
                  font: {
                    size: 12, // Font size
                  },
                },
                grid: {
                  lineWidth: 1, // Grid line width
                  color: '#232D37', // Set grid line color
                  display: false, // Hide vertical grid lines
                },
                border: {
                  display: false, // Show the left border line
                },
                suggestedMin: -300,
                offset: true, // Add padding to the left and right sides of the x-axis
              },
              y: {
                min: bedTimeHistoryChartData.params.min_y, // Minimum value for y-axis
                max: bedTimeHistoryChartData.params.max_y, // Maximum value for y-axis
                ticks: {
                  autoSkip: false,
                  stepSize: (bedTimeHistoryChartData.params.max_y - bedTimeHistoryChartData.params.min_y) / 5, // Step size for ticks
                  callback: (value) => `${value}`, // Format tick labels
                  color: '#005550', // Set y-axis label color
                  font: {
                    size: 12, // Font size
                  },
                  padding: 10,
                  mirror: false, // Prevent ticks from appearing on both sides
                  display: true, // Show y-axis ticks
                  z: 0,
                },
                grid: {
                  color: '#C3C8CD', // Set grid line color
                  lineWidth: 1, // Grid line width
                  drawTicks: false, // Remove tick marks
                  display: true, // Show horizontal grid lines
                  z: 0,
                },
                border: {
                  display: false, // Show the left border line
                },
                title: {
                  display: true,
                  text: bedTimeHistoryChartData.params.y_label,
                  font: {
                    size: 14,
                  },
                  color: '#005550',
                },
              },
            },
            layout: {
              padding: {
                left: 0,
                right: 10,
                top: 10,
                bottom: 0,
              },
            },
          },
        });
      }
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy(); // Clean up on unmount
      }
    };
  }, [bedTimeHistoryChartData]);

  return (
    <div className={`${styles.boxBedTime} ${styles.box} ${styles.boxGray}`}>
      <p className={styles.boxTitle}>
        {bedTimeHistoryChartData.params.y_label}
        {' '}
        History
      </p>
      <p className={styles.boxSubtitle}>Weekly Average</p>
      <div className={styles.boxBedTimeImage}>
        <canvas ref={chartRef}/>
      </div>
    </div>
  );
};

export default BedTimeHistoryChart;
