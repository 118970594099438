import React from 'react';
import styles from "./HHSReport.module.less";

export type ContributingHabitsData = {
  name: string,
  params: {
    habits: [
      { habit_name: string, normalized_value: number },
    ]
  },
  type: string,
};

const ContributingHabits = (props: {
  data: ContributingHabitsData,
}) => {
  const { data } = props;

  // Extract labels and values from data
  const values = data.params.habits.map((habit) => habit.normalized_value);

  // Determine colors based on normalized_value
  const backgroundColors = values.map((value) => (value >= 0 ? '#64C800' : '#C80000'),
  );

  const positiveHabits = data.params.habits.filter((habit) => habit.normalized_value >= 0);
  const negativeHabits = data.params.habits.filter((habit) => habit.normalized_value < 0);

  const habitItems = [...positiveHabits, ...negativeHabits].map((habit, index) => (
    <div
      key={habit.habit_name}
      className={styles.habitScoreListItem}
      style={{
        flexDirection: habit.normalized_value < 0 ? "row-reverse" : 'row', // Align red bars to the left
        marginRight: habit.normalized_value < 0 ? "-12%" : 0, // Align red bars to the left
      }}
    >
      <p
        className={styles.habitScoreListItemTitle}
        style={{
          textAlign: habit.normalized_value < 0 ? "left" : 'right',
          marginRight: habit.normalized_value < 0 ? 0 : '16px',
          marginLeft: habit.normalized_value < 0 ? '16px' : 0,
        }}
      >
        {habit.habit_name}
      </p>
      <div className={styles.habitScoreListItemRangeContainer}>
        <div
          className={styles.habitScoreListItemRange}
          style={{
            width: `${Math.abs(habit.normalized_value * 100)}%`, // Scale value to percentage width
            backgroundColor: backgroundColors[index],
            marginLeft: habit.normalized_value >= 0 ? 'auto' : 0, // Align green bars to the right
            marginRight: habit.normalized_value < 0 ? 'auto' : 0, // Align red bars to the left
            right: habit.normalized_value < 0 ? 0 : 'auto',
            left: habit.normalized_value < 0 ? 'auto' : 0,
          }}
        />
      </div>
    </div>
  ));

  return (
    <div className={`${styles.contributingHabits} ${styles.box} ${styles.boxGray}`}>
      <p className={styles.boxTitle}>Contributing Habits</p>
      <div className={styles.habitScoreImage}>
        <div className={styles.habitScoreList}>
          {habitItems}
        </div>
      </div>
      <div className={styles.flexWrap}>
        <div className={styles.helper}>
          <svg
            className={styles.helperIcon}
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="9"
            viewBox="0 0 12 9"
            fill="none"
          >
            <path d="M6 9L0.803849 -9.78799e-07L11.1962 -7.02746e-08L6 9Z" fill="#C80000"/>
          </svg>
          <p className={`${styles.helperText} ${styles.redText}`}>Hurts</p>
        </div>
        <div className={styles.helper}>
          <p className={`${styles.helperText} ${styles.greenText}`}>Helps</p>
          <svg
            className={styles.helperIcon}
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="9"
            viewBox="0 0 12 9"
            fill="none"
          >
            <path d="M6 0L11.1962 9L0.803848 9L6 0Z" fill="#64C800"/>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default ContributingHabits;
