import { createAsyncThunk } from '@reduxjs/toolkit';
import bruApi from "../../../services/Customer/bruApi";

export const parseFiles = createAsyncThunk(
  'user/parseFiles',
  async (data: { fileIds: string[], selectedCountryAbbr: string }) => ({
    parsedFiles: await bruApi.parseFiles(data.fileIds),
    selectedCountryAbbr: data.selectedCountryAbbr,
  }),
);

export default {
  parseFiles,
};
