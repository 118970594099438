import { AxiosInstance } from "axios";
import { AgreementStatus } from "../../interfaces/AskIt/AskIt";
import userAuthorizedAskItAxios from "./userAuthorizedAskItAxios";
import CustomerAuthTokenProvider from "./customerAuthTokenProvider";
import { fetchJwtToken } from "./userAuthorizedAxios";
import userAuthorizedGatewayAxios from "./userAuthorizedGatewayAxios";

const provideApis = (userAuthorizedGatewayAxios: AxiosInstance) => ({
  getAgreementStatus: (): Promise<AgreementStatus> => userAuthorizedGatewayAxios
    .get('/askit/profile/customer/v1/info')
    .then((response: any) => response.data),

  postAgreementStatus: () => userAuthorizedGatewayAxios.post('/askit/profile/customer/v1/agreement'),

  postChatSessionMessagesById: async (message: string, chatSessionId: string) => {
    const expiresIn = CustomerAuthTokenProvider.getSecondsToExpiration();

    if (expiresIn < 100) {
      await fetchJwtToken();
    }

    const baseUrl = userAuthorizedGatewayAxios.defaults.baseURL;

    return await fetch(`${baseUrl}/askit/chat/customer/v1/sessions/${chatSessionId}/messages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${CustomerAuthTokenProvider.get()}`,
        // Authorization: `Bearer 96827`,
        "X-Customer-Time-Zone": Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      body: JSON.stringify({
        content: message,
      }),
    });
  },

  postChatSession: () => userAuthorizedGatewayAxios.post('/askit/chat/customer/v1/sessions')
    .then((response: any) => response.data),

  getChatSessions: () => userAuthorizedGatewayAxios
    .get('/askit/chat/customer/v1/sessions', {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response: any) => response.data),

  getChatSessionMessagesById: (chatSessionId: number) => userAuthorizedGatewayAxios.get(`/askit/chat/customer/v1/sessions/${chatSessionId}/messages`, {
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response: any) => response.data),
});

export const askItBetaApi = provideApis(userAuthorizedGatewayAxios);
export default provideApis(userAuthorizedAskItAxios);
