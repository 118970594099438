import baseAxios from "../baseAxios";
import {
  EmailFeedbackReference,
  EmailPreferencesReference,
} from "../../interfaces/EmailPreferences/EmailPreferences";

export default {
  getEmailPreferencesReferences: (): Promise<EmailPreferencesReference[]> => baseAxios.get('/api/customer/v1/notifications/email-preference-types')
    .then((res) => res.data.items),
  getEmailFeedbackReferences: (): Promise<EmailFeedbackReference[]> => baseAxios.get('/api/customer/v1/notifications/email-preferences-options')
    .then((res) => res.data),

  getSelectedSubscriptionTypes: (email: string): Promise<string[]> => baseAxios.get(`/api/public/v1/notifications/hubspot-email-preferences/${email}`)
    .then((res) => res.data.selectedSubscriptionTypes),

  putSelectedSubscriptionTypes: (email: string, subscriptionTypes: string[]) => baseAxios.put('/api/public/v1/notifications/email-preferences', { email, subscriptionTypes }),

  postEmailPreferencesFeedback: (emailAddress: string, optionIds: number[], customOptionMessage: string): Promise<void> => baseAxios
    .post('/api/customer/v1/notifications/email-preferences-feedback', { emailAddress, optionIds, customOptionMessage }),
};
