export default {
  admin: {
    dashboard: '/admin',
    customers: '/admin/customers',
    demoPage: '/admin/demo',
    loginPage: '/admin/login',
    analyticsPage: '/admin/analytics',
    secondFactorAuth: {
      selectProviderPage: '/admin/second-factor-login',
      smsCode: {
        phoneVerificationPage: '/admin/second-factor-login/phone-verification',
        smsCodeVerificationPage: '/admin/second-factor-login/sms-code-verification',
      },
      googleAuthentication: {
        setUpPage: '/admin/second-factor-login/set-up-google-authenticator',
        verifyCodePage: '/admin/second-factor-login/verify-google-authenticator',
      },
    },

    dnaKit: {
      view: '/admin/dna-kit/view',
    },

    audit: {
      statusLog: '/admin/audit/status-log',
    },

    customerDeleteRequestsPage: '/admin/customer-delete-requests',

    customerManagementPage: '/admin/customers',

    partnersIntegration: '/admin/partners-integration',
    customersCsvImport: '/admin/customers-csv-import',

    storeOrderManagementPageView: '/admin/store_order/view',
    storeOrderItemEditPage: '/admin/customers/view-order-edit',
    storeAddonsPage: '/admin/customers/addons',
    redirectToDeepDive: '/admin/redirect-to-customer-dd',

    customerManagement: {
      dna: {
        dnaSectionPage: '/admin/customers/dna',
        viewDnaResult: '/admin/genetics/show-dna-result', // and dna ID
      },
    },

    runUtilityScriptsPage: '/admin/utility-scripts/run',

    hubSpotManagementPage: '/admin/hub-spot-management',
    hubSpotManagement: {
      propertiesPage: '/admin/hub-spot-management/properties',
    },

    export: {
      queryPage: '/admin/export/query-list',
    },

    reports: {
      discountUsedReportPage: '/admin/reports/discount-used-report/',
    },

    companies: {
      mainPage: '/admin/companies',
      orders: '/admin/companies/orders/',
    },

    markers: {
      addName: '/admin/marker/marker-add-name',
      requests: '/admin/marker/review-requests',
    },
  },
  customer: {
    settings: {
      healthProfile: '/customer/settings',
      account: '/customer/account',
    },
    askIt: '/customer/ask-it',
    orders: {
      learnPage: '/customer/category-testing/learn-more',
    },
    myResults: {
      category_recommendations: '/customer/bloodwork/category-recommendations',
      category_results: '/customer/bloodwork/category-results',
      category_learn: '/customer/bloodwork/category-learn',
      marker_recommendations: '/customer/bloodwork/marker-recommendations',
      marker_results: '/customer/bloodwork/marker-results',
      marker_learn: '/customer/bloodwork/marker-learn',
      list: '/customer/bloodwork/list',
      table: '/customer/bloodwork/table',
      groups: '/customer/bloodwork/categories',
      markerPageByAbbr: '/customer/bloodwork/marker', // /customer/bloodwork/marker/mg
      main: '/customer/bloodwork',
    },
    team: {
      acceptInvite: '/customer/team/invitation/:inviteId/accept-invite',
    },
    diy: {
      uploadBloodResult: '/customer/diy/upload-blood-result',
      uploadBloodResultProcessing: '/customer/diy/upload-blood-result/processing',
      uploadBloodResultConfirmation: '/customer/diy/upload-blood-result/confirmation',
      uploadBloodResultSuccess: '/customer/diy/upload-blood-result/success',
    },
  },
  public: {
    hhr: `/hhr-report/:reportToken`,
  },
};
