import userAuthorizedGatewayAxios from "./userAuthorizedGatewayAxios";

export type ReportItem = {
  id: string,
  dateFrom: string,
  dateTo: string | null,
  generatedAt: string,
  title: string,
  type: string,
};

export default {
  getHHSReports: (page: number, pageSize: number): Promise<{
    totalPages: number,
    totalElements: number,
    items: ReportItem[],
  }> => userAuthorizedGatewayAxios
    .get(`/report-hub/customer/v1/reports/HHS?page=${page}&size=${pageSize}`)
    .then((response) => response.data),

  getReportHash: (reportType: string, reportId: string): Promise<string> => userAuthorizedGatewayAxios
    .get(`/report-hub/customer/v1/reports/${reportType}/${reportId}/hash`)
    .then((response) => response.data.hashId),

  getConnectedDevices: (): Promise<string[]> => userAuthorizedGatewayAxios
    .get(`/api/customer/v1/devices/status`)
    .then((response) => response.data.connectedDevices),

  getReportView: (reportToken: string): Promise<any> => userAuthorizedGatewayAxios
    .get(`/report-hub/public/v2/reports/file/${reportToken}/view`)
    .then((response) => response.data),
};
